import React, {useContext} from "react";
import {Context} from "../Context";

function ShowAll(){

    const{isAllFlipped, setIsAllFlipped}=useContext(Context)

    function handleClick(){
        !isAllFlipped && setIsAllFlipped(true)
    }

    const color = "#000"

    return(
        <button className="my-button round-button" onClick={()=>handleClick()}>
            <svg width="40px" height="40px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill={color} d="M1.81818182,1.36363636 C1.5671433,1.36363636 1.36363636,1.5671433 1.36363636,1.81818182 L1.36363636,18.1818182 C1.36363636,18.4328567 1.5671433,18.6363636 1.81818182,18.6363636 L18.1818182,18.6363636 C18.4328567,18.6363636 18.6363636,18.4328567 18.6363636,18.1818182 L18.6363636,1.81818182 C18.6363636,1.5671433 18.4328567,1.36363636 18.1818182,1.36363636 L1.81818182,1.36363636 Z M18.1818182,0 C19.1859723,0 20,0.814027728 20,1.81818182 L20,18.1818182 C20,19.1859723 19.1859723,20 18.1818182,20 L1.81818182,20 C0.814027728,20 0,19.1859723 0,18.1818182 L0,1.81818182 C0,0.814027728 0.814027728,0 1.81818182,0 L18.1818182,0 Z M14.9217059,6.56354053 C14.6410521,6.3124843 14.210016,6.33647785 13.9589598,6.61713161 L13.9589598,6.61713161 L9.54702033,11.5492037 L6.71542243,8.51893854 C6.45832765,8.24380578 6.02687198,8.2291831 5.75173921,8.48627788 C5.47660645,8.74337266 5.46198377,9.17482834 5.71907855,9.4499611 L5.71907855,9.4499611 L9.05982608,13.0250982 C9.33334374,13.3178061 9.79907323,13.312746 10.0661666,13.0141645 L10.0661666,13.0141645 L14.975297,7.52628664 C15.2263532,7.24563287 15.2023597,6.81459676 14.9217059,6.56354053 Z"/>
            </svg>
            <span className="tooltip">Show all</span>
        </button>
    )
}

export default ShowAll